<template>
  <div class="min-h-screen bg-blue-400">
    <TnHeader />
    <img
      v-if="canShowClouds"
      src="@/assets/cloud-left.svg"
      alt="Cloud Left"
      class="absolute left-0 top-[258px] hidden sm:block"
    />
    <img
      v-if="canShowClouds"
      src="@/assets/cloud-right-home.svg"
      alt="Cloud Right"
      class="absolute right-0 top-[259px] hidden sm:block"
    />
    <slot />
    <TnFooter />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const canShowClouds = computed(() => route.name === 'home');
</script>
